<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">휴가기간</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                  v-model="vacationDateRange"
                  type="lookup-condition"
                />
              </li>
            </ul>
          </li>
          <li class="field caddyName">
            <div class="title">캐디명</div>
            <ul class="content">
              <li class="item input">
                <input-text
                  id="caddieName"
                  v-model.trim="searchConditions.caddieName"
                  @focus="searchValueClear"
                  @keydown.native="searchValueKeyDown"
                />
              </li>
            </ul>
            <ul class="content">
              <li class="item button">
                <ul class="button">
                  <li class="search">
                    <erp-button
                        button-div="GET"
                        @click.native="onSearchCaddieInfo"
                    >
                      검색
                    </erp-button>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :ignore="isPopupOpened"
              :is-shortcut-button="true"
              @click.native="onViewCaddieInfo"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">캐디 휴가 목록</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button" style="margin-right: 140px">
                <li class="save keyColor">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      :is-key-color="true"
                      @click.native="onSaveCaddieInfo"
                  >
                    저장
                  </erp-button>
                </li>
                <li class="add">
                  <erp-button
                      button-div="SAVE"
                      :ignore="isPopupOpened"
                      :is-icon-custom="true"
                      :is-custom-shortcut-button="true"
                      shortcut-key="caddieInfoVacation.shortcuts.add"
                      :shortcut="{key: 'F3'}"
                      :disabled="isModifyMode"
                     @click.native="onAddCaddieInfo"
                  >
                    추가
                  </erp-button>
                </li>
                <li class="delete">
                  <erp-button
                      button-div="DELETE"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      :disabled="isModifyMode"
                      @click.native="onDeleteCaddieInfo"
                  >
                    삭제
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onClickExcel"> Excel </erp-button>
                </li>
              </ul>
              <div class="header-switch">
                <div class="title">
                  멀티 편집모드
                </div>
                <div class="switch">
                  <ejs-switch
                    v-model="isModifyMode"
                    :checked="isModifyMode"
                    @change="onModifySwitchChanged"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="section-body">
            <ejs-grid-wrapper
              ref="caddieInfoGrid"
              :provides="caddieInfoGridOptions.provides"
              :columns="caddieInfoGridOptions.columns"
              :dataSource="caddieVacationList"
              :selectionSettings="selectionSettings"
              :pageSettings="caddieInfoGridOptions.pageSettings"
              :editSettings="caddieInfoGridOptions.editSettings"
              :validationRules="caddieInfoGridOptions.validationRules"
              :allowPaging="true"
              :allowFiltering="!isModifyMode"
              :allowSorting="!isModifyMode"
              :isAutoSelectCell="!isModifyMode"
              :isCustomizeKeyboardAction="true"
              @headerCellInfo="headerCellInfo"
              @queryCellInfo="queryCellInfo"
              @recordClick="recordClick"
              @actionComplete="caddieInfoGridActionComplete"
              @cellSaved="onCaddieInfoGridCellSaved"
              @cellSelected="onCaddieInfoGridCellSelected"
            />
          </div>
        </section>
      </article>
    </div>
    <caddie-select-popup
      v-if="isCaddieSelectPopupOpen"
      ref="caddieSelectPopup"
      @popupClosed="closeCaddieSelectPopup"
      @popupConfirmed="caddieSelectPopupConfirmed"
    />
    <edit-multiple-columns-popup
      v-if="isEditMultipleColumnsPopupOpen"
      ref="editMultipleColumnsPopup"
      @popupConfirm="onEditMultipleColumnsPopupConfirm"
      @popupClosed="onEditMultipleColumnsPopupClose"
    />
  </div>
</template>

<style scoped>
body .appContent .lookup-condition .field.caddyName .content .item.input {width: 90px;}
body .appContent .body-article .section-body {overflow: hidden;border: none;}
</style>

<script>
import moment from "moment";
import {
  getCaddieInfoVacation,
  putCaddieInfoVacation,
  getCaddieInfoVacationCheck,
} from "@/api/caddieInfoVacation";
import { getFormattedDate } from "@/utils/date";
import { commonCodesGetComName } from "@/utils/commonCodes";
import { isGridModified } from "@/utils/gridUtil";
import { numberWithCommas } from "@/utils/number";
import {
  Edit,
  ForeignKey,
  Selection,
  Page,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import InputText from "@/components/common/text/InputText";
import InputDateRange from "@/components/common/datetime/InputDateRange";
import CaddieSelectPopup from "../popup/CaddieSelectPopup";
import editMultipleColumnsPopup from '@/views/common/EditMultipleColumnsPopup';
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "caddieInfoVacation",
  components: {
    InputText,
    InputDateRange,
    CaddieSelectPopup,
    editMultipleColumnsPopup,
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  created() {
    this.searchConditions.vacationStartDate = getFormattedDate(new Date());
    this.searchConditions.vacationEndDate = getFormattedDate(new Date());
    this.searchConditions.vacationStartDayOfTheWeek = commonCodesGetComName(
      "DW_CODE",
      String(new Date().getDay() + 1)
    );
    this.searchConditions.vacationEndDayOfTheWeek = commonCodesGetComName(
      "DW_CODE",
      String(new Date().getDay() + 1)
    );
    this.getCaddieInfoVacation();
  },
  data() {
    return {
      count: 0,
      caddieVacationListLength: 0,
      caddieVacationList: [],
      isCaddieSelectPopupOpen: false,
      isEditMultipleColumnsPopupOpen: false,
      field: null, //textField: 전체 검색 창에서 검색 , gridField: 그리드 내 row 개별 검색
      addedRecordList: [], //grid 내에서 동명이인 팝엄 검색전 save된 row list
      searchConditions: {
        //textField
        caddieId: null, //textField에서 검색 시 caddieId가 존재하는 경우 caddieName 아닌 caddieId로 조회됨.
        caddieName: null,
        vacationStartDate: null,
        vacationEndDate: null,
        vacationStartDayOfTheWeek: null,
        vacationEndDayOfTheWeek: null,
      },
      searchOptions: {
        //gridField
        caddieName: null,
      },
      selectionSettings: {
        type: "Multiple",
        mode: "Both",
        enableToggle: false,
      },
      isModifyMode: false,
    };
  },
  computed: {
    isPopupOpened() {
      return (
        this.isCaddieSelectPopupOpen ||
        this.isEditMultipleColumnsPopupOpen
      );
    },
    vacationDateRange: {
      get: function () {
        return {
          from: this.searchConditions.vacationStartDate,
          to: this.searchConditions.vacationEndDate,
        };
      },
      set: function (vacationDateRange) {
        this.searchConditions.vacationStartDate = vacationDateRange.from;
        this.searchConditions.vacationEndDate = vacationDateRange.to;
      },
    },
    caddieInfoGridOptions() {
      return {
        provides: [Edit, ForeignKey, Selection, Page, ExcelExport],
        pageSettings: { pageSize: 50 },
        editSettings: {
          allowEditing: true,
          allowAdding: true,
          allowDeleting: true,
          mode: "Batch",
          showConfirmDialog: false,
          newRowPosition: "Bottom",
        },
        validationRules: {
          caddieName: {
            required: true,
          },
          caddieNo: {
            required: true,
          },
          caddieAttendDiv: {
            required: true,
          },
          vacationStartDate: {
            required: true,
            dateFormat: true,
          },
          vacationEndDate: {
            required: true,
            dateFormat: true,
            custom: {
              method: (row) => {
                return !moment(row.vacationStartDate).isAfter(
                  row.vacationEndDate
                );
              },
              message: "main.validationMessage.vacationDateMessage",
            },
          },
        },
        columns: [
          { field: "caddieId", allowEditing: false, visible: false },
          {
            field: "vacationId",
            allowEditing: false,
            type: "number",
            isPrimaryKey: true,
            visible: false,
          },
          {
            field: "caddieName",
            headerText: "캐디명",
            allowEditing: true,
            type: "string",
            width: 90,
            textAlign: "left",
          },
          {
            field: "caddieNameIcon",
            headerText: "",
            allowEditing: false,
            type: "string",
            width: 25,
            textAlign: "left",
          },
          {
            field: "caddieNo",
            headerText: "캐디번호",
            allowEditing: false,
            type: "string",
            width: 90,
            textAlign: "left",
          },
          {
            field: "caddieAttendDiv",
            headerText: "휴가구분",
            allowEditing: true,
            type: "string",
            width: 180,
            editType: "dropdownedit",
            textAlign: "left",
            isCommonCodeField: true,
            groupCode: 'CADDIE_ATTEND_DIV',
          },
          {
            field: "vacationStartDate",
            headerText: "휴가시작일",
            allowEditing: true,
            type: "string",
            width: 120,
            textAlign: "left",
            isDateType: true,
          },
          {
            field: "vacationEndDate",
            headerText: "휴가종료일",
            allowEditing: true,
            type: "string",
            width: 120,
            textAlign: "left",
            isDateType: true,
          },
          {
            field: "remarks",
            headerText: "비고",
            allowEditing: true,
            type: "string",
            textAlign: "left",
            maxLength: 400,
            isRemarks: true,
          },
        ],
      };
    },
  },
  methods: {
    numberWithCommas,
    searchValueClear() {
      this.searchConditions.caddieName = null;
      this.searchConditions.caddieId = null;
    },
    searchValueKeyDown(event) {
      this.searchConditions.caddieId = null; //동명이인 팝업을 거치지 않고 검색할시 기존에 셋팅된 Id를 제거
      if (event.key === "Enter") {
        this.getCaddieInfoVacation();
      }
    },
    queryCellInfo(args) {
      const {
        column: {field},
        cell,
      } = args;
      if (
        field === "caddieName" ||
        field === "vacationStartDate" ||
        field === "caddieAttendDiv" ||
        field === "vacationEndDate" ||
        field === "remarks"
      ) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (field === "caddieNameIcon") {
        cell.classList.add(this.$t("className.grid.searchIconArea"));
      }
      if (this.isModifyMode) {
        const allowedEditColumns = this.caddieInfoGridOptions.columns
          ?.filter(item => item.allowEditing && item.field !== 'caddieName')
          ?.map(item => item.field);
        if (!allowedEditColumns.includes(field)) {
          cell.style.backgroundColor = '#f9f9f9';
          cell.style.pointerEvents = 'none';
        }
      }
    },
    headerCellInfo(args) {
      const {
        cell: {
          column: {
            field,
            headerText,
          },
        },
        node,
      } = args;
      if (
        field === "caddieName" ||
        field === "caddieAttendDiv" ||
        field === "vacationStartDate" ||
        field === "vacationEndDate"
      ) {
        node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (field === "caddieNameIcon") {
        node.classList.add(this.$t("className.grid.searchIconArea"));
      }
      if (this.isModifyMode && headerText !== 'NO') {
        const allowedEditColumns = this.caddieInfoGridOptions.columns
          ?.filter(item => item.allowEditing && item.field !== 'caddieName')
          ?.map(item => item.field);
        if (allowedEditColumns.includes(field)) {
          node.style.backgroundColor = 'rgb(237, 246, 250)';
          node.addEventListener('click', async () => await this.onGridHeaderClicked(args.cell.column), false);
        }
      }
    },
    async onGridHeaderClicked(column) {
      if (!column?.field) {
        return;
      }
      const gridRefs = this.$refs.caddieInfoGrid;
      const selectedRowIndexes = [...new Array(gridRefs?.getBatchCurrentViewRecords().length).keys()];
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
    recordClick(args) {
      if (!!args.column && args.column.field === "caddieNameIcon") {
        this.openCaddieSelectPopup(
          args.cell.previousElementSibling.innerText,
          "gridField",
          args.rowIndex
        );
      }
    },
    isValidVacationDate() {
      if (!this.searchConditions.vacationStartDate) {
        this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["시작일자"])
        );
        return false;
      }
      if (!this.searchConditions.vacationEndDate) {
        this.errorToast(
          this.$t("main.validationMessage.requiredMessage", ["종료일자"])
        );
        return false;
      }

      return true;
    },
    onSearchCaddieInfo() {
      this.openCaddieSelectPopup(this.searchConditions.caddieName, "textField");
    },
    async onViewCaddieInfo() {
      if (!this.isValidVacationDate()) {
        return;
      }
      if (isGridModified(this.$refs.caddieInfoGrid)) {
        if (!(await this.confirm("수정 내역이 있습니다. 진행하시겠습니까?"))) {
          return;
        }
      }
      this.getCaddieInfoVacation();
    },
    onAddCaddieInfo() {
      this.$refs.caddieInfoGrid.addRecord({
        caddieId: null,
        caddieName: null,
        caddieNo: null,
        caddieAttendDiv: null,
        vacationStartDate: getFormattedDate(new Date()),
        vacationEndDate: getFormattedDate(new Date()),
        remarks: null,
      });

      const data = this.$refs.caddieInfoGrid.getBatchCurrentViewRecords();

      this.$refs.caddieInfoGrid.editCell(data.length - 1, "caddieName");
    },
    async onSaveCaddieInfo() {
      const {
        addedRecords,
        changedRecords,
        deletedRecords,
      } = this.$refs.caddieInfoGrid.getBatchChanges();

      if (
        addedRecords.length === 0 &&
        changedRecords.length === 0 &&
        deletedRecords.length === 0
      ) {
        this.errorToast("변경사항이 없습니다");
        return;
      }

      if (!this.$refs.caddieInfoGrid.validate()) {
        return;
      }

      try {
        const promises = addedRecords
          .concat(changedRecords)
          .map((item) =>
            getCaddieInfoVacationCheck(
              item.caddieId,
              item.vacationStartDate,
              item.vacationEndDate,
              item.vacationId
            )
          );
        const validations = await Promise.all(promises);

        console.log(validations);
        if (
          validations
            .map(({ status }) => status === "OK")
            .reduce((acc, val) => acc && val, true)
        ) {
          await putCaddieInfoVacation(
            addedRecords,
            deletedRecords,
            changedRecords
          );
          this.getCaddieInfoVacation();
          this.infoToast(this.$t("main.popupMessage.saved"));
        }
      } catch (e) {
        console.error(e);
      }
    },
    async onDeleteCaddieInfo() {
      const deletedData = this.$refs.caddieInfoGrid
        .getSelectedRecords()
        .filter((item) => !!item.caddieId);

      if (deletedData.length > 0) {
        if (!(await this.confirm("선택하신 데이터를 삭제하시겠습니까?"))) {
          return;
        }

        await putCaddieInfoVacation(null, deletedData, null);

        this.infoToast(this.$t("main.popupMessage.deleted"));

        this.getCaddieInfoVacation();
      } else {
        this.$refs.caddieInfoGrid.deleteRecord();
      }
    },
    openCaddieSelectPopup(caddieName, field, rowIndex) {
      this.isCaddieSelectPopupOpen = true;
      this.$nextTick(() => {
        this.$refs.caddieSelectPopup.showPopup(caddieName, field, rowIndex);
      });
    },
    closeCaddieSelectPopup() {
      this.isCaddieSelectPopupOpen = false;
    },
    caddieSelectPopupConfirmed(data) {
      this.isCaddieSelectPopupOpen = false;
      if (data.selectedRowData) {
        if (data.field === "gridField") {
          this.searchOptions.caddieName = data.selectedRowData.caddieName;

          this.$nextTick(() => {
            this.$refs.caddieInfoGrid.updateRow(
              data.rowIndex,
              {
                caddieId: data.selectedRowData.caddieId,
                caddieName: data.selectedRowData.caddieName,
                caddieNo: data.selectedRowData.caddieNo
              }
            );
          });
        } else if (data.field === "textField") {
          this.searchConditions.caddieName = data.selectedRowData.caddieName;
          this.searchConditions.caddieId = data.selectedRowData.caddieId;
        }
      }
    },
    onEditMultipleColumnsPopupConfirm(args) {
      const {
        field,
        value,
        rowIndexes,
      } = args;
      this.isEditMultipleColumnsPopupOpen = false;
      if (rowIndexes.length < 1) {
        return;
      }
      rowIndexes.forEach(index => {
        this.$refs.caddieInfoGrid.updateCell(
          index,
          field,
          value,
        );
      });
    },
    onEditMultipleColumnsPopupClose() {
      this.isEditMultipleColumnsPopupOpen = false;
    },
    getCaddieInfoVacation() {
      const {
        caddieId,
        caddieName,
        vacationStartDate,
        vacationEndDate,
      } = this.searchConditions;

      getCaddieInfoVacation(
        caddieId,
        caddieId ? null : caddieName,
        vacationStartDate,
        vacationEndDate
      )
        .then((response) => {
          const caddieVacationList = response.value.caddieVacationList;
          this.caddieVacationList = caddieVacationList.map((item, index) => {
            item["caddieName"] || (item["caddieName"] = null);
            item["remarks"] || (item["remarks"] = null);

            return {
              ...item,
              _rid: index + 1,
            };
          });

          this.caddieVacationListLength = this.caddieVacationList.length;
        })
        .catch((error) => {
          console.error("getCaddieInfoVacation.err ===>", error);
        });
    },
    caddieInfoGridActionComplete() {
      this.count = numberWithCommas(
        this.$refs.caddieInfoGrid?.getGridBatchCount() || 0
      );
    },
    onCaddieInfoGridCellSaved(args) {
      const columnName = args.columnName;
      const value = args.value;
      const previousValue = args.previousValue;

      if (columnName === "caddieName" && value !== previousValue) {
        // const rowIndexes = this.$refs.caddieInfoGrid.getSelectedRowIndexes(); // Enter시 Row가 변경되므로 getSelectedRowIndexes 사용 불가.
        const rowIndex = args.cell.parentElement.rowIndex;
        const caddieName = value;
        this.openCaddieSelectPopup(
          caddieName,
          "gridField",
          //rowIndexes[0]
          rowIndex
        );
      }
    },
    onClickExcel() {
      this.$refs.caddieInfoGrid.excelExport();
    },
    onModifySwitchChanged(args) {
      const isChecked = args.checked;
      this.selectionSettings = isChecked
        ? {cellSelectionMode: 'Box', type: 'Multiple', mode: 'Cell'}
        : {type: 'Multiple', mode: 'Both', enableToggle: false};
      this.$refs.caddieInfoGrid.refresh();
      if (isChecked) {
        this.infoToast('멀티 편집할 셀 하나와 여러 로우를 드래그하세요.');
      }
    },
    async onCaddieInfoGridCellSelected(args) {
      if (!this.isModifyMode) {
        return;
      }
      const {
        cellIndex: {cellIndex},
        selectedRowCellIndex
      } = args;
      if (selectedRowCellIndex[0]?.cellIndexes.length > 1) {
        this.errorToast('편집할 셀 하나만 선택해주세요.');
        return;
      }
      let columns = [];
      this.caddieInfoGridOptions.columns
        ?.forEach(column => {
          if (column?.columns) {
            column.columns
              ?.forEach(c => columns.push(c));
          } else {
            columns.push(column);
          }
        });
      const allowedEditColumns = columns
        ?.filter(item => item.allowEditing && item.field !== 'caddieName')
        ?.map(item => item.field);
      const column = columns[cellIndex - 1];
      if (!allowedEditColumns.includes(column?.field)) {
        this.errorToast('편집할 수 있는 셀이 아닙니다.');
        return;
      }
      const selectedRowIndexes = selectedRowCellIndex.map(item => item.rowIndex);
      if (selectedRowIndexes.length < 1) {
        return;
      }
      this.isEditMultipleColumnsPopupOpen = true;
      await this.$nextTick();
      this.$refs.editMultipleColumnsPopup.showPopup(
        column,
        selectedRowIndexes
      );
    },
  },
};
</script>
