import request from "@/utils/request";

/**
 * @return Promise
 */
export function getCaddieInfoVacation(
  caddieId,
  caddieName,
  vacationStartDate,
  vacationEndDate
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/vacation",
    method: "get",
    params: {
      caddieId,
      caddieName,
      vacationEndDate,
      vacationStartDate,
    },
  });
}

/**
 * @return Promise
 */
export function putCaddieInfoVacation(
  addedRecords,
  deletedRecords,
  updatedRecords
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/vacation",
    method: "put",
    data: {
      addedRecords,
      deletedRecords,
      updatedRecords,
    },
  });
}

/**
 * @return Promise
 */
export function getCaddieInfoVacationCheck(
  caddieId,
  vacationStartDate,
  vacationEndDate,
  vacationId
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/game_management/caddie/info/vacation/holiday-check",
    method: "get",
    params: {
      caddieId,
      vacationStartDate,
      vacationEndDate,
      vacationId,
    },
  });
}
